import React from 'react';
import {
  Added,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="March 2020" subnav="release-notes">
      <div id="March2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          This is it. Connect 4, Final 4, Fantastic 4, and now Uniform version
          4. There's a lot here, so let's get started.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Search functionality! Thanks to our friends at Algolia, you can
                now quickly find exactly what you're looking for.
              </Added>
              <Improved>
                New look for the site. Nothing too drastic, but we think you'll
                agree it definitely feeeeels more clean.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.0.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.0.0"
              upgradeUrl="https://github.com/hudl/uniform-ui-components/wiki/to-v4.0.0"
            />

            <div className={styles.changesList}>
              <Added>
                React 16 support. Uniform now uses the latest and greatest
                features it has to offer.
              </Added>
              <Added>
                Typescript support. Enjoy typeahead features and inline
                documentation.
              </Added>
              <Added>
                CSS variable support. We're paving the way for future theming
                support.
              </Added>
              <Added>
                <Link href="/components/forms/parent-checkbox/design">
                  Parent checkbox
                </Link>{' '}
                for any parent-child checkbox need.
              </Added>
              <Added>
                Brand new icons and categories, some of which even animate.
                <List>
                  <li>
                    <Link href="/components/icons/reactions/design">
                      Reactions
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link href="/components/icons/logos/design">Logos</Link>
                  </li>
                </List>
              </Added>
              <Improved>
                Our <Link href="/components/icons/code">icon</Link> component by
                making each its own individual component.
              </Improved>
              <Improved>
                Design for most components to embrace a more elite feel with
                sharper corners and sweeter animations.
              </Improved>
              <Improved>
                Our prop naming scheme.
                <List>
                  <li>Props are now more predictable.</li>
                  <li>
                    <code>bools</code> and <code>functions</code> are
                    standardized.
                  </li>
                </List>
              </Improved>
              <Improved>
                Our select setup. We've combined them all{' '}
                <Link href="/components/forms/select/design">
                  into one component
                </Link>{' '}
                and made them far more extensible—karate chop!
              </Improved>
              <Improved>
                Our <Link href="/components/tooltip/design">tooltip</Link>{' '}
                component to act as it should, everywhere.
              </Improved>
              <Removed>
                Sass support - yup, it's gone, which means you'll need to make
                sure you follow that Upgrade guide to get everything switched
                over.
              </Removed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
